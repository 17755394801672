import { MapStyle } from '../../common/types';
import { getStoredConfig } from '../../common/utils';
import { SwitchMapStyleState } from './slice';

export const getStateFromLocalStorage = (initialState: SwitchMapStyleState): SwitchMapStyleState => {
  const config = getStoredConfig();
  const state = { ...initialState };

  if (config?.vesselPageMapStyleName) {
    state.selectedVesselMapStyle = config.vesselPageMapStyleName;
  }

  return state;
};

const navigationalMapStyleName: MapStyle = 'navigational';

export const navigationalMapStyle: mapboxgl.Style = {
  name: navigationalMapStyleName,
  version: 8,
  sources: {
    'raster-tiles': {
      type: 'raster',
      tiles: ['https://wms.transas.com/TMS/1.0.0/TX97/{z}/{x}/{y}.png?token=96ffae12-563b-41b3-9cbc-e1295ff6cd14'],
      tileSize: 256,
      attribution:
        '<a href="https://www.wartsila.com/transas/tx-97" target="_blank">© Wärtsilä Nautical Chart TX-97</a>',
      scheme: 'tms',
    },
  },
  layers: [
    {
      id: 'simple-tiles',
      type: 'raster',
      source: 'raster-tiles',
      minzoom: 0,
      maxzoom: 17,
    },
  ],
};
